<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 h-8">
      <h2 class="text-lg font-medium mr-auto">主題管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!isEditing && isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>

              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
            </div>
          </div>
        </div>

        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增主題
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form class="xl:flex sm:mr-auto" @submit.prevent="onFilter">
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.commitProxy('reload')"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.commitProxy('reload')"
            >
              搜尋
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('csv')"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <vxe-grid
          id="grid"
          ref="grid"
          class="mt-5 table-report"
          v-bind="gridOptions"
        >
          <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="detailEdit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
                <!-- <button
                  class="button rounded-full mr-1 mb-2 bg-theme-12 text-gray-700"
                  title="複製"
                  @click="clone(row)"
                >
                  <FontAwesome icon="copy" class="w-4 h-4" />
                </button> -->
              </div>
            </slot>
          </template>
          <template #name_filter="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #published="{ row }">
            <vxe-switch
              v-model="row.Published"
              open-label="上架"
              close-label="下架"
              :disabled="!isEditing"
              @change="switchChange(row, $event)"
            ></vxe-switch>
          </template>
        </vxe-grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from "vue";
import CloudFun, {
  Condition,
  LogicalConnective,
  Operator,
  ref,
  Sorting,
  SortOrder,
} from "@cloudfun/core";
import formatDate from "xe-utils/toDateString";

import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
} from "vxe-table";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const customFilters = ref<Condition[]>([]);
    const keyword = ref("");
    const editingRow = ref(null);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: false,
      width: "100%",
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: {
        remote: true,
        multiple: false,
        defaultSort: { field: "CreatedTime", order: "desc" },
      },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      //   exportConfig: {
      //     filename: "主題清單",
      //     type: "csv",
      //     types: ["html", "csv"],
      //     mode: "all",
      //     modes: ["current", "selected", "all"],
      //     columns: printColumns,
      //   },
      columns: [
        // { type: "checkbox", width: 35, fixed: "left", resizable: false },
        {
          field: "CreatedTime",
          title: "建檔時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 180,
          resizable: true,
          formatter: ({ cellValue }) => {
            return cellValue === null
              ? ""
              : formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm");
          },
        },
        {
          field: "Title",
          title: "主題名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Introduction",
          title: "連結網址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Ordinal",
          title: "排序值",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Published",
          title: "上架",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          width: "100",
          slots: { default: "published" },
          // formatter: ({ cellValue }) => (cellValue == 0 ? "否" : "是"),
        },
        {
          title: "操作",
          width: 180,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            // customFilters.value.push(
            //   new Condition("Type", Operator.Equal, ProductType.Ticket)
            // );
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            // console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/load", queryParams).then(
                (payload) => {
                  console.log(payload);
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            debugger;
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    const switchChange = async (row: any, $event: any) => {
      const payload = await model?.dispatch("topic/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("topic/update", payload);
      }
    };

    return {
      gridOptions,
      grid,
      keyword,
      editingRow,
      isRowSelected,
      isEditing,
      switchChange,
    };
  },
  methods: {
    addNewRow() {
      this.$router.push({ path: "/topic/detail" });
    },
    detailEdit(row: any) {
      this.$router.push({ path: "/topic/detail", query: { id: row.Id } });
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("topic/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
  },
});
</script>

<style scoped></style>
