
import { computed, defineComponent, reactive } from "vue";
import CloudFun, {
  Condition,
  LogicalConnective,
  Operator,
  ref,
  Sorting,
  SortOrder,
} from "@cloudfun/core";
import formatDate from "xe-utils/toDateString";

import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
} from "vxe-table";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const customFilters = ref<Condition[]>([]);
    const keyword = ref("");
    const editingRow = ref(null);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: false,
      width: "100%",
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: {
        remote: true,
        multiple: false,
        defaultSort: { field: "CreatedTime", order: "desc" },
      },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      //   exportConfig: {
      //     filename: "主題清單",
      //     type: "csv",
      //     types: ["html", "csv"],
      //     mode: "all",
      //     modes: ["current", "selected", "all"],
      //     columns: printColumns,
      //   },
      columns: [
        // { type: "checkbox", width: 35, fixed: "left", resizable: false },
        {
          field: "CreatedTime",
          title: "建檔時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 180,
          resizable: true,
          formatter: ({ cellValue }) => {
            return cellValue === null
              ? ""
              : formatDate(new Date(cellValue), "yyyy/MM/dd HH:mm");
          },
        },
        {
          field: "Title",
          title: "主題名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Introduction",
          title: "連結網址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Ordinal",
          title: "排序值",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "name_filter" },
        },
        {
          field: "Published",
          title: "上架",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          width: "100",
          slots: { default: "published" },
          // formatter: ({ cellValue }) => (cellValue == 0 ? "否" : "是"),
        },
        {
          title: "操作",
          width: 180,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            // customFilters.value.push(
            //   new Condition("Type", Operator.Equal, ProductType.Ticket)
            // );
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            // console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/load", queryParams).then(
                (payload) => {
                  console.log(payload);
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            debugger;
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("topic/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    const switchChange = async (row: any, $event: any) => {
      const payload = await model?.dispatch("topic/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("topic/update", payload);
      }
    };

    return {
      gridOptions,
      grid,
      keyword,
      editingRow,
      isRowSelected,
      isEditing,
      switchChange,
    };
  },
  methods: {
    addNewRow() {
      this.$router.push({ path: "/topic/detail" });
    },
    detailEdit(row: any) {
      this.$router.push({ path: "/topic/detail", query: { id: row.Id } });
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("topic/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
  },
});
